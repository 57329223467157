let slideshowSwiper = new Swiper('.slideshow .swiper:not(.swiper--fullscreen)', {
	slidesPerView:      'auto',
	spaceBetween:       16,
	autoHeight:         true,
	navigation:         {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	slidesOffsetBefore: 32,
	slidesOffsetAfter:  32,
	breakpoints: {
		992: {
			slidesOffsetBefore: function () {
				const spaziatura = this.el.dataset.spaziatura;
				let offsetWidth = window.visualViewport.width / 12 * spaziatura + 8;

				return offsetWidth;
			},
			slidesOffsetAfter:  8,
		}
	}
});


let fullscreenSwiper = new Swiper('.swiper--fullscreen', {
	slidesPerView: 1,
	speed:         600,
	effect:        'fade',
	fadeEffect:    {
		crossFade: false
	},
	loop:          true,
	autoplay:      false,
	navigation:         {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination:    {
		el:        '.swiper-pagination',
		clickable: false
	},
	on:            {
		autoplayStart:   function (swiper) {
			let bullets = swiper.pagination.bullets;

			setTimeout(function () {
				bullets[0].classList.add('animate');
			}, 5);
		},
		realIndexChange: function (swiper) {
			let activeIndex = swiper.realIndex;
			let bullets = swiper.pagination.bullets;

			if (activeIndex === 0)
			{
				bullets.forEach((bullet, i) => {
					bullet.classList.remove('animate');
				});
			}

			for (let i = 0; i < activeIndex; i++)
			{
				bullets[i].classList.add('no-animation');
			}

			bullets[activeIndex].classList.remove('no-animation');

			setTimeout(function () {
				bullets[activeIndex].classList.add('animate');
			}, 5);
		}
	}
});


let sprintSwiper = new Swiper('.sprint .swiper', {
	slidesPerView:      'auto',
	spaceBetween:       16,
	autoHeight:         true,
	navigation:         false,
	slidesOffsetBefore: 32,
	slidesOffsetAfter:  32,
	breakpoints: {
		992: {
			slidesOffsetBefore: function () {
				const spaziatura = this.el.dataset.spaziatura;
				let offsetWidth = window.visualViewport.width / 12 * spaziatura + 8;

				return offsetWidth;
			},
			slidesOffsetAfter:  8,
		}
	}
});