import {goToByScroll} from './go-to-by-scroll';
import {utils} from "./utils";
//import { gsap } from '../libs/gsap.min';
//import { ScrollTrigger } from '../libs/ScrollTrigger.min';

const body = document.body;


/**
 * Marquee
 */
document.querySelectorAll('.marquee-inner').forEach((el, i) => {

	let contentLength = el.textContent.trim().length;
	let speedCoefficient = 0.2; // higher values for slower speed

	// adapt marquee speed based on content length
	el.style.animationDuration = contentLength * speedCoefficient + 's';

	utils.duplicateChildNodes(el);
	utils.duplicateChildNodes(el);
});

const progettiGrid = document.querySelector('.progetti-grid');

if (progettiGrid !== null)
{
	const isotopeProgettiGrid = new Isotope(progettiGrid, {
		itemSelector:    '.grid-item',
		percentPosition: true,
		layoutMode:      'fitRows',
		fitRows:         {
			gutter: 0
		}
	});

	imagesLoaded(progettiGrid).on('progress', function () {
		// layout Isotope after each image loads
		isotopeProgettiGrid.layout();
	});

	// call equalHeights script on arrangeComplete
	isotopeProgettiGrid.on('layoutComplete arrangeComplete', function (filteredItems) {
	});

	// bind filter button click
	let filtersElem = document.querySelector('.filter-values-list');
	filtersElem.addEventListener('click', function (event) {
		// only work with buttons
		if (!matchesSelector(event.target, 'span'))
		{
			return;
		}
		let filterValue = event.target.getAttribute('data-filter');
		isotopeProgettiGrid.arrange({filter: filterValue});
	});

	// change is-checked class on buttons
	let buttonGroups = document.querySelectorAll('.filter-values-list');

	for (let i = 0, len = buttonGroups.length; i < len; i++)
	{
		let buttonGroup = buttonGroups[i];
		radioButtonGroup(buttonGroup);
	}

	function radioButtonGroup(buttonGroup) {
		buttonGroup.addEventListener('click', function (event) {
			// only work with buttons
			if (!matchesSelector(event.target, 'span'))
			{
				return;
			}
			buttonGroup.querySelector('.is-checked').classList.remove('is-checked');
			event.target.classList.add('is-checked');
		});
	}

}


/**
 * Change class of labels on click
 * @type {NodeListOf<Element>}
 */
const customToggles = document.querySelectorAll('.wpcf7-checkbox input, .wpcf7-radio input');

customToggles.forEach((el) => {
	let label = el.parentNode;

	let inputname = el.getAttribute('name');
	let inputType = el.getAttribute('type');

	// init state
	if (el.checked)
	{
		label.classList.add('active');
	}
	else
	{
		label.classList.remove('active');
	}

	// change state
	el.addEventListener('change', function (e) {
		e.stopPropagation();

		// remove the active state from the other radio inputs with the same name
		if (inputType === 'radio')
		{
			let siblingInputs = document.querySelectorAll('input[name="' + inputname + '"]');

			siblingInputs.forEach((input) => {
				input.parentNode.classList.remove('active')
			});
		}

		if (el.checked)
		{
			label.classList.add('active');
		}
		else
		{
			label.classList.remove('active');
		}
	});
});


const expandBioButton = document.querySelector('.expand-bio')

if (expandBioButton !== null)
{
	expandBioButton.addEventListener('click', function () {

		let content = document.querySelector('.complete-bio');

		if (content !== null)
		{
			utils.slideToggle(this.parentNode, 600);

			utils.slideToggle(content, 3000);
		}
	});
}


const closeSwiperButtons = document.querySelectorAll('.swiper__close')

closeSwiperButtons.forEach((el) => {
	el.addEventListener('click', function (e) {
		const swiperFullscreen = el.closest('.swiper--fullscreen');
		swiperFullscreen.classList.remove('active');

		// after the div is closed reset swiper active slide and bullets and stop autoplay
		setTimeout(function () {
			swiperFullscreen.swiper.autoplay.stop();
			swiperFullscreen.swiper.slideToLoop(0, 0, true);

			setTimeout(function () {
				let bullets = swiperFullscreen.swiper.pagination.bullets;

				bullets.forEach((bullet, i) => {
					bullet.classList.remove('animate', 'no-animation');
				});
			}, 5);
		}, 300);
	});
});


const swiperSlideshowsSlides = document.querySelectorAll('.swiper:not(.swiper--fullscreen) .swiper-slide');

swiperSlideshowsSlides.forEach((el) => {
	el.addEventListener('click', function (e) {
		const swiper = el.closest('.swiper');
		const swiperFullscreen = swiper.nextElementSibling;

		if (swiperFullscreen !== null)
		{
			swiperFullscreen.classList.add('active');

			if (!swiperFullscreen.swiper.autoplay.running)
			{
				swiperFullscreen.swiper.params.autoplay = {
					delay:                3000,
					disableOnInteraction: true,
					stopOnLastSlide:      false
				};
				swiperFullscreen.swiper.autoplay.start();
			}
		}
	});
});


/**
 * ON LOAD
 */

window.addEventListener('load', function (event) {


});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {
	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
window.addEventListener('resizeEnd', function () {



});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/